import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import classNames from 'classnames'

import Overlay from 'components/shared/Overlay'
import Form from 'components/shared/Form'
import Container from 'components/shared/Container'
import Input from 'components/shared/Input'
import Icon from 'components/shared/Icon'
import Button from 'components/shared/Button'
import getFormattedPrice from 'components/helpers/getFormattedPrice'
import calculateCartDiscounts from 'components/helpers/calculateCartDiscounts'

import { TINY, SMALL, LARGE, DOLLAR, PERCENTAGE, BRAND, SLIDE_NEXT, NOTIFY, SUCCESS, NEUTRAL, CHEVRON, CART, LINE_ITEM } from 'components/constants'

const CartDiscountModal = ({
  width=620,
  rounded=4,
  theme=BRAND,
  zIndex=5,
  data,
  onCloseOverlay,
  onSetDiscount,
  pushDiscount,
  modalContext,
}) => {

  const [quantity, setQuantity] = useState(undefined)
  const [originalPrice, setOriginalPrice] = useState(undefined)
  const [maxDiscountPercent, setMaxDiscountPercent] = useState(undefined)
  const [maxDiscountValue, setMaxDiscountValue] = useState(undefined)
  const [minimumSellPrice, setMinimumSellPrice] = useState(undefined)
  const [priceMinusDiscounts, setPriceMinusDiscounts] = useState(undefined)

  const [overlayOpen, setOverlayOpen] = useState(data.hasOwnProperty('cartItem') || data.hasOwnProperty('cartItems'))
  const [price, setPrice] = useState(0)
  const [error, setError] = useState(false)

  const [inputFocus, setInputFocus] = useState(null)
  const [cartDiscount, setCartDiscount] = useState(data.cartItems && (data.cartItems[0].order_level_discount || 0))
  
  useMemo(()=>{
    //initial setter (pre-render)
    modalContext === LINE_ITEM && setLineItemVariables()
    modalContext === CART && setCartVariables()
  },[])

  useEffect(() => {
    //update setter
    modalContext === LINE_ITEM && setLineItemVariables()
    modalContext === CART && setCartVariables()
  },[data])

  function setLineItemVariables() {
    setQuantity(data.cartItem.quantity)
    setOriginalPrice(data.cartItem.price)
    setPrice(data.cartItem.price - data.cartItem.discount)
    let percentCalc = Math.max((data.cartItem.minimum_sell_price / data.cartItem.price) * 100 || 0, data.user.maximumDiscountPercentage || 0)
    setMaxDiscountPercent(percentCalc)
    setMaxDiscountValue(Math.round(percentCalc * (data.cartItem.price / 100) * 100) / 100)
    setMinimumSellPrice(Math.round(Math.max(data.cartItem.minimum_sell_price, (data.cartItem.price - (percentCalc * (data.cartItem.price / 100)))) * 100) / 100)
  }

  function setCartVariables() {
    let minSellPrice = 0;
    data.cartItems.forEach((cartItem) => {
      let percentage = data.user.maximumDiscountPercentage, 
          price;
      if (cartItem.minimum_sell_price) {
        percentage = Math.min((100 - (cartItem.minimum_sell_price / (cartItem.price * 100))), data.user.maximumDiscountPercentage)
      }
      price = (cartItem.price - (cartItem.price * (percentage / 100))) * cartItem.quantity;
      minSellPrice += price;
    })
    let lineItemDiscountCalc = (pushDiscount && pushDiscount.amount) || data.cartItems.reduce((num, item) => num + (item.discount * item.quantity), 0),
        originalPriceCalc = data.cartItems.reduce((num, item) => num + (item.price * item.quantity), 0),
        maxDiscountPercentCalc = 100 - (minSellPrice / originalPriceCalc * 100) - ((lineItemDiscountCalc / originalPriceCalc) * 100)
    setOriginalPrice(originalPriceCalc)
    setPriceMinusDiscounts(originalPriceCalc - lineItemDiscountCalc)
    setMaxDiscountPercent(maxDiscountPercentCalc)
    setMaxDiscountValue(Math.round(maxDiscountPercentCalc * (originalPriceCalc / 100) * 100) / 100 || 0)
  }

  function handleChange(value, type) {
    value = Math.round(value * 100) / 100
    if(typeof(value) === 'number') {
      let updatedPrice = null
      switch (type) {
        case 'unitValue':
          updatedPrice = originalPrice - value
          if(value <= maxDiscountValue) {
            inputFocus ===  'unitValue' && setPrice(updatedPrice);
          }
          updatedPrice < minimumSellPrice ? setError(true) : setError(false)
          return
        case 'percentValue':
          updatedPrice = originalPrice - ((originalPrice * value) / 100)
          inputFocus === 'percentValue' && setPrice(updatedPrice)
          updatedPrice < minimumSellPrice ? setError(true) : setError(false)
          return
        case 'wholeValue':
          updatedPrice = originalPrice - (value / quantity)
          inputFocus ===  'wholeValue' && setPrice(updatedPrice)
          updatedPrice < minimumSellPrice ? setError(true) : setError(false)
          return
        case 'wholePrice':
          if(value <= maxDiscountValue) {
            inputFocus ===  'wholePrice' && setCartDiscount((value * 100) / originalPrice);
            setError(false)
          } else {
            setError(true)
          }
          return
        case 'percentPrice':
          if(value <= maxDiscountPercent) {
            inputFocus ===  'percentPrice' && setCartDiscount(value);
            setError(false)
          } else {
            setError(true)
          }
          return
      }
    }
  }

  function updateCart() {
    let cartDiscountPercent = calculateCartDiscounts(data.cartItems, data.user.maximumDiscountPercentage, cartDiscount),
        cart = structuredClone(data.cartItems);
    cart.forEach((cartItem) => cartItem.order_level_discount = cartDiscountPercent)
    onSetDiscount(cart)
  }

  function handleSubmit() {
    if (modalContext === LINE_ITEM) {
      onSetDiscount(price, data.cartItem);
    } else if (modalContext === CART) {
      updateCart();
    }
    setOverlayOpen(false)
    onCloseOverlay()
  }

  function renderTable() {
    let tablePrice = price
    if (modalContext === CART) {
      tablePrice = (originalPrice - cartDiscount);
    } 
    const icon = typeof(tablePrice) == "number" && (originalPrice !== tablePrice) && (
      <Icon
        shade={5}
        size={TINY}
        className={"ml-1"}
        rotate={originalPrice < tablePrice && 180}
        theme={originalPrice < tablePrice ? NOTIFY : SUCCESS}
        type={CHEVRON}
      />
    )
    const errorMessage = (
      <div className={`absolute expand flex justify-center align-items-center `} style={{backgroundColor: "rgba(255,255,255,0.75)"}}>
        <p className="color-notify-5 font-weight-3 font-size-2">
          Discount exceeds the maximum allowed ({getFormattedPrice(maxDiscountValue)} each)
        </p>
      </div>
    )
    switch(modalContext) {
      case LINE_ITEM:
        return (
          <div className={`grid me-3 relative`} style={{gridTemplateColumns: "auto auto auto auto"}}>
            {error && errorMessage}
            <p className={`${error === false && 'opacity-05'} border-bottom text-center`}>Qty</p>
            <p className={`${error === false && 'opacity-05'} border-bottom text-center`}>Unit Price</p> 
            <p className={`${error === false && 'opacity-05'} border-bottom text-center`}>Adjusted Price</p>
            <p className={`${error === false && 'opacity-05'} border-bottom text-center`}>Adjusted Total</p>
            <p className="text-center">{quantity}</p>
            <p className="text-center">${originalPrice && originalPrice.toFixed(2)}</p>
            <p className="text-center">
              {getFormattedPrice((price).toFixed(2) || '-')}
              {icon}
            </p>
            <p className="text-center">
              {getFormattedPrice((price * quantity).toFixed(2) || '-')}
              {icon}
            </p>
          </div>
        )
      case CART:
        return (
          <div className={`grid me-3 relative`} style={{gridTemplateColumns: "auto auto"}}>
            {error && errorMessage}
            <p className={`${error === false && 'opacity-05'} border-bottom text-center`}>Price</p>
            <p className={`${error === false && 'opacity-05'} border-bottom text-center`}>Adjusted Total</p>
            <p className="text-center">
              {getFormattedPrice(priceMinusDiscounts ? (priceMinusDiscounts).toFixed(2) : '-')}
            </p>
            <p className="text-center">
              {getFormattedPrice(priceMinusDiscounts ? (priceMinusDiscounts - ((cartDiscount * originalPrice) / 100) || 0).toFixed(2) : '-')}
              {icon}
            </p>
          </div>
        )
    }
  }
  
    
  switch (modalContext) {
    case CART:
      return (
        <>
          {overlayOpen === true && (
            <AnimatePresence>
              <Overlay
                opacity={.75}
                zIndex={zIndex}
                onClose={() => { setOverlayOpen(false); onCloseOverlay(); }}
              >
                <Form
                  maxWidth={width}
                  shade={0}
                  shadow={4}
                  rounded={rounded}
                  theme={theme}
                  className={classNames({ ['z-' + zIndex]: !!zIndex })}
                >
                  <Container
                    animation={SLIDE_NEXT}
                    className="p-5"
                  >
                    <h3>Create Cart Total Adjustment</h3>
                    <div className="grid align-items-center me-3" style={{gridTemplateColumns: '1fr 20px 1fr'}}>
                      <label htmlFor="amountPerUnit" className="opacity-05">Discount ($)</label>&nbsp;
                      <label htmlFor="percentage" className="opacity-05">Discount (%)</label>
                      <Input
                        id='wholePrice'
                        name='wholePrice'
                        type='number'
                        max={maxDiscountValue && maxDiscountValue}
                        theme={error ? NOTIFY : NEUTRAL}
                        className='border'
                        size={SMALL}
                        icon={DOLLAR}
                        width={250}
                        pushValue={cartDiscount ? ((cartDiscount * originalPrice) / 100).toFixed(2) : 0}
                        onFocus={() => setInputFocus('wholePrice')}
                        onBlur={() => setInputFocus(null)}
                        onChange={(value) => inputFocus ===  'wholePrice' && handleChange(value, 'wholePrice')}
                      />
                      <span className="spacer text-center opacity-03">|</span>
                      <Input
                        id='percentPrice'
                        name='percentPrice'
                        className='border'
                        type='number'
                        max={maxDiscountPercent && maxDiscountPercent}
                        theme={error ? NOTIFY : NEUTRAL}
                        size={SMALL}
                        icon={PERCENTAGE}
                        width={250}
                        pushValue={cartDiscount.toFixed(2)}
                        onFocus={() => setInputFocus('percentPrice')}
                        onBlur={() => setInputFocus(null)}
                        onChange={(value) => inputFocus ===  'percentPrice' && handleChange(value, 'percentPrice')}
                      />
                    </div>
                    { renderTable() }
                  </Container>
                  <Button
                    shade={error ? 1 : 3}
                    rounded={0}
                    theme={error ? NEUTRAL : SUCCESS }
                    size={LARGE}
                    disabled={error}
                    onClick={handleSubmit}
                  >
                    Set new price
                  </Button>
                </Form>
              </Overlay>
            </AnimatePresence>
          )}
        </>
      )
    case LINE_ITEM:
      return (
        <>
          {overlayOpen === true && (
            <AnimatePresence>
              <Overlay
                opacity={.75}
                zIndex={zIndex}
                onClose={() => { setOverlayOpen(false); onCloseOverlay(); }}
              >
                <Form
                  maxWidth={width}
                  shade={0}
                  shadow={4}
                  rounded={rounded}
                  theme={theme}
                  className={classNames({ ['z-' + zIndex]: !!zIndex })}
                >
                  <Container
                    animation={SLIDE_NEXT}
                    className="p-5"
                  >
                    <h3>Adjust {data.cartItem.name} price</h3>
                    <div className="grid align-items-center me-3" style={{gridTemplateColumns: '1fr 20px 1fr 20px 1fr'}}>
                      <label htmlFor="amountPerUnit" className="opacity-05">Discount each ($)</label>&nbsp;
                      <label htmlFor="percentage" className="opacity-05">Discount (%)</label>&nbsp;
                      <label htmlFor="wholePrice" className="opacity-05">Discount total ($)</label>
                      <Input
                        id='unitValue'
                        name='unitValue'
                        className='border'
                        type='number'
                        max={maxDiscountValue && parseFloat(maxDiscountValue) || 0}
                        theme={error ? NOTIFY : NEUTRAL}
                        size={SMALL}
                        icon={DOLLAR}
                        width={150}
                        pushValue={price ? (originalPrice - price).toFixed(2) : 0}
                        onFocus={() => setInputFocus('unitValue')}
                        onBlur={() => setInputFocus(null)}
                        onChange={(value) => inputFocus ===  'unitValue' && handleChange(value, 'unitValue')}
                      />
                      <span className="spacer text-center opacity-03">|</span>
                      <Input
                        id='percentValue'
                        name='percentValue'
                        type='number'
                        max={maxDiscountPercent && parseFloat(maxDiscountPercent) || 0}
                        theme={error ? NOTIFY : NEUTRAL}
                        className='border'
                        size={SMALL}
                        icon={PERCENTAGE}
                        width={150}
                        pushValue={price ? (((originalPrice - price) / originalPrice) * 100).toFixed(2) : 0}
                        onFocus={() => setInputFocus('percentValue')}
                        onBlur={() => setInputFocus(null)}
                        onChange={(value) => inputFocus ===  'percentValue' && handleChange(value, 'percentValue')}
                      />
                      <span className="spacer text-center opacity-03">|</span>
                      <Input
                        id='wholeValue'
                        name='wholeValue'
                        className='border'
                        type='number'
                        max={maxDiscountValue && parseFloat(maxDiscountValue * quantity || 0)}
                        theme={error ? NOTIFY : NEUTRAL}
                        size={SMALL}
                        icon={DOLLAR}
                        width={150}
                        pushValue={price ? ((originalPrice - price) * quantity).toFixed(2) : 0}
                        onFocus={() => setInputFocus('wholeValue')}
                        onBlur={() => setInputFocus(null)}
                        onChange={(value) => inputFocus ===  'wholeValue' && handleChange(value, 'wholeValue')}
                      />
                    </div>
                    { renderTable() }
                  </Container>
                  <Button
                    shade={error ? 1 : 3}
                    rounded={0}
                    theme={error ? NEUTRAL : SUCCESS }
                    size={LARGE}
                    disabled={error}
                    onClick={handleSubmit}
                  >
                    Set new price
                  </Button>
                </Form>
              </Overlay>
            </AnimatePresence>
          )}
        </>
      )
  }
}


export default CartDiscountModal