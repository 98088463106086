import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { NEUTRAL, BRAND, DASHBOARD, SCALE_IN, CART, LINE_ITEM } from 'components/constants'
import { PARKED } from 'components/orders/constants'
import { CHECKOUT } from 'components/checkout/constants'
import { NEXT } from 'constants/motion'

import Cart from 'components/cart'
import CartDiscountModal from 'components/cart/CartDiscountModal'
import Main from 'components/shared/Main'
import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'
import Products from 'components/products'

import CreateCustomer from 'components/customers/actions/CreateCustomer'
import DecrementCartItem from 'components/checkout/actions/DecrementCartItem'
import DiscountCartItem from 'components/checkout/actions/DiscountCartItem'
import IncrementCartItem from 'components/checkout/actions/IncrementCartItem'
import RemoveCartItem from 'components/checkout/actions/RemoveCartItem'
import ResetCartItems from 'components/checkout/actions/ResetCartItems'
import SetCartItemSerials from 'components/checkout/actions/SetCartItemSerials'
import SetCartItemPrice from 'components/checkout/actions/SetCartItemPrice'
import SetCustomer from 'components/checkout/actions/SetCustomer'
import ResetCustomer from 'components/checkout/actions/ResetCustomer'
import SetOrder from 'components/orders/actions/SetOrder'
import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import SetViewId from 'components/navigation/actions/SetViewId'
import SetViewParents from 'components/navigation/actions/SetViewParents'
import SetCartItemFulfillment from 'components/checkout/actions/SetCartItemFulfillment'
import DeleteCartItemFulfillment from 'components/checkout/actions/DeleteCartItemFulfillment'
import scaffoldOrder from 'components/orders/helpers/scaffoldOrder'
import canAbandonOrder from 'components/orders/helpers/canAbandonOrder'

import useSyncProducts from 'hooks/useSyncProducts'
import useSyncOrders from 'hooks/useSyncOrders'

import SetCartItems from 'components/checkout/actions/SetCartItems'
import setCartItemsImagesFromProducts from 'components/order/helpers/setCartItemsImagesFromProducts'
import getPaymentData from 'components/orders/helpers/getPaymentData'
import calculateCartDiscounts from 'components/helpers/calculateCartDiscounts'

import Overlay from 'components/shared/Overlay'
import OrderActionsModal from 'components/shared/OrderActionsModal'

export default function ({
  theme = BRAND,
}) {
  const dispatch = useDispatch()
  const { createOrder } = useSyncOrders({ poll: false })
  const { loading, products } = useSyncProducts({ poll: true })

  const order = useSelector((state) => state.order)
  const cartItems = useSelector((state) => state.cartItems)
  const customer = useSelector((state) => state.customer)
  const shift = useSelector((state) => state.shift)
  const store = useSelector((state) => state.store)
  const user = useSelector((state) => state.user)
  const outlets = useSelector((state) => state.outlets)
  const currentOutlet = useSelector((state) => state.outlet)

  // Cart Menu
  const [actionId, setActionId] = useState(null)
  const [actionsAnimation, setActionsAnimation] = useState(null)
  const [showActions, setShowActions] = useState(actionId !== null)
  const [paymentData, setPaymentData] = useState(getPaymentData(cartItems, store.taxInclusive))

  const [discountModal, setDiscountModal] = useState({})
  const [pushDiscount, setPushDiscount] = useState(null)

  // Continue orders will only available for parked orders
  useEffect(() => {
    if (order && order.items && order.orderType === PARKED) {
      dispatch(SetCartItems(order.items))
      dispatch(SetOrder({}))
    }
  }, [order])

  // Recalculate any cart discounts when the cart updates
  useEffect(() => {
    const cartDiscountPercent = calculateCartDiscounts(cartItems, user.maxDiscountPercentage)
    if (cartItems[0] && cartItems[0].order_level_discount !== cartDiscountPercent) {
      let cart = structuredClone(cartItems);
      cart.forEach((cartItem) => cartItem.order_level_discount = cartDiscountPercent)
      dispatch(SetCartItems(cart))
    }
  }, [cartItems])

  // The introduction of "returns" is a primary factor in scenarios
  // where the cart contains items prior to loading the dashboard.
  // This scenario can only happen now when a return process is initiated
  // on an order summary page. We want to set the image of the cart item since
  // items are coming from the order items and we do not store image in it.
  // Also, this makes sure that it's always a fresh order when Dashboard mounts.
  useEffect(() => {
    dispatch(SetOrder({}))

    if (cartItems.length > 0) {
      const items = setCartItemsImagesFromProducts(cartItems, products)
      dispatch(SetCartItems(items))
    }
  }, [])

  useEffect(() => {
    setPaymentData(getPaymentData(cartItems, store.taxInclusive))
  }, [cartItems])

  function quicklistProducts() {
    const quicklistProductIds = currentOutlet.quicklist_product_ids

    // Check if quicklistProductIds is falsy or an empty array
    if (!quicklistProductIds || quicklistProductIds.length === 0) return []

    const customSort = (productA, productB) => {
      // Convert the position values to numbers for comparison
      let positionA = productA.position !== null ? Number(productA.position) : Infinity
      let positionB = productB.position !== null ? Number(productB.position) : Infinity

      if (positionA === positionB) {
        return 0;
      } else if (positionA === Infinity) {
        return 1
      } else if (positionB === Infinity) {
        return -1
      } else {
        return positionA - positionB;
      }
    }

    // Used set and has here for efficiency given that the store have thousands of products.
    // Using Set and .has sets the constant time complexity to O(1), meaning the time needed for filter execution
    // remainins constant even as the products grows bigger.
    const idsToFilter = new Set(quicklistProductIds)
    const filteredProducts = products.filter(product => idsToFilter.has(product.id))
    return filteredProducts.sort(customSort)
  }

  function checkout() {
    dispatch(SetViewAnimation(NEXT))
    dispatch(SetViewParents([DASHBOARD]))
    dispatch(SetViewId(CHECKOUT))
  }

  function park() {
    dispatch(ResetCartItems())
    createOrder(scaffoldOrder({
      cartItems,
      customer,
      orderType: PARKED,
      sync: false,
      shift,
      store,
      user,
    }))
  }

  function handleSetDiscount(price, cartItem) {
    const object = { id: cartItem.id, amount: cartItem.price - price }
    setPushDiscount(object)
    dispatch(DiscountCartItem(object))
  }

  return (
    <View theme={NEUTRAL} shade={1}>
      {discountModal.cartItem && (
        <CartDiscountModal
          data={discountModal}
          modalContext={LINE_ITEM}
          pushDiscount={pushDiscount && pushDiscount}
          onCloseOverlay={() => setDiscountModal({})}
          onSetDiscount={(price, cartItem) => { handleSetDiscount(price, cartItem) }}
        />
      )}
      <Topbar
        className='border-bottom'
        shade={0}
        theme={NEUTRAL}
      />
      {showActions === true && (
        <Overlay
          onClose={() => {
            setActionId(null)
            setActionsAnimation(SCALE_IN)
            setShowActions(false)
          }}
        >
          <OrderActionsModal
            initialAnimation={actionsAnimation}
            customer={customer}
            cartItems={cartItems}
            context={CART}
            user={user}
            canSetNotes={false}
            canEmail={false}
            id={actionId}
            theme={theme}
            total={paymentData.total}
            canPark={cartItems.length > 0}
            canAbandon={canAbandonOrder(order)}
            showBackButton={true}
            onSetId={(id) => setActionId(id)}
            onExit={() => {
              back()
              reset()
            }}
            onPark={() => {
              setActionId(null)
              setShowActions(false)
              dispatch(ResetCustomer())
              park()
            }}
            onBack={() => {
              setActionId(null)
            }}
            onClose={() => {
              setActionId(null)
              setShowActions(false)
            }}
            onAbandon={() => {
              abandonParkedOrder(order.id)
              dispatch(ResetCartItems())
            }}
            onSetDiscount={(cart) => {
              dispatch(SetCartItems(cart))
              setActionId(null)
              setShowActions(false)
            }}
          />
        </Overlay>
      )}
      <Main gridTemplateColumns='1fr 440px'>
        <Products
          products={products}
          quicklistProducts={quicklistProducts()}
          loading={loading}
        />
        <Cart
          customer={customer}
          cartItems={cartItems}
          user={user}
          taxInclusive={store.taxInclusive}
          outlets={outlets}
          onPark={park}
          onCheckout={checkout}
          onReset={() => dispatch(ResetCartItems())}
          onRemove={(id) => dispatch(RemoveCartItem(id))}
          onDecrement={(id) => dispatch(DecrementCartItem(id))}
          onIncrement={(id) => dispatch(IncrementCartItem(id))}
          onCreateCustomer={(customer) => dispatch(CreateCustomer(customer))}
          onSetCustomer={(customer) => dispatch(SetCustomer(customer))}
          pushDiscount={pushDiscount && pushDiscount}
          onSetSerials={(payload) => dispatch(SetCartItemSerials(payload))}
          onSetPrice={(payload) => dispatch(SetCartItemPrice(payload))}
          onClickDiscount={(payload) => { setDiscountModal(payload) }}
          onClickOpenOrderActions={() => setShowActions(true)}
          onSetFulfillment={(payload) => dispatch(SetCartItemFulfillment(payload))}
          onDeleteFulfillment={(payload) => dispatch(DeleteCartItemFulfillment(payload))}
        />
      </Main>
    </View>
  )
}
